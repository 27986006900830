import { Box, Spinner, useColorModeValue } from '@chakra-ui/react';

export const LoadingState = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const spinnerColor = useColorModeValue('blue.500', 'blue.200');
  const spinnerBgColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg={bgColor}
      zIndex="9999"
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={spinnerBgColor}
          color={spinnerColor}
          size="xl"
        />
      </Box>
    </Box>
  );
};
