import React from 'react';

import { Box, ChakraProvider } from '@chakra-ui/react';
import { theme } from 'src/utils/theme';
import { useIsFetching } from 'react-query';
import { LoadingState } from 'src/components/Layout/LoadingState';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  const isLoadingUser = useIsFetching({
    predicate: (query) => {
      if (query.queryKey === 'accessLevel') {
        return query.state.status === 'loading';
      }
      return false;
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Box flex="1">
          {isLoadingUser > 0 && <LoadingState />}

          {props.children}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Layout;
