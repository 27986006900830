import { useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';

interface IMenuContextData {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const MenuContext = createContext({} as IMenuContextData);

export function MenuProvider({ children }: PropsWithChildren<unknown>) {
  const router = useRouter();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isMenuOpen, setMenuOpen] = useLocalStorage('isMenuOpen', false);

  const onOpen = useCallback(() => {
    setMenuOpen(true);
  }, [setMenuOpen]);

  const onClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  useEffect(() => {
    if (isMobile) {
      onClose();
    }
  }, [router.pathname, onClose, isMobile]);

  return (
    <MenuContext.Provider value={{ isOpen: isMenuOpen, onOpen, onClose }}>
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => {
  const context = useContext(MenuContext);

  return context;
};
